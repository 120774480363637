<script setup lang="ts">
import axios from "axios";
import qs from 'qs';
import {onBeforeUnmount, Ref, ref} from "vue";
import { useUserStore } from "../stores/UserStore";
import {AlertMessage, CatalogFilters, CatalogItem, CatalogStats} from "../types";
import Alert from "./Alert.vue";
import { debounce } from "lodash";
import Battery from "./Battery.vue";
import IntegrationTypeIcon from "./IntegrationTypeIcon.vue";
import Grade from "./Grade.vue";
import DropdownFilter from "./DropdownFilter.vue";
import {useI18n} from "vue-i18n";
import CatalogUpload from "./CatalogUpload.vue";
import {Channel} from "pusher-js";
import Broadcaster from "../pusher";

const {t} = useI18n({useScope: "global"});

type CatalogResponse = {
    items: Array<CatalogItem>;
    stats: CatalogStats;
    filters: CatalogFilters;
    items_count: number;
};

let notificationChannel: Channel|null = null;

const subscribe = () => {
    if (notificationChannel !== null) {
        return;
    }

    console.log('Subscribing...');
    notificationChannel = Broadcaster.get().connector.subscribe(`private-user.${user.profile?.id}.notifications`);
}

const unsubscribe = () => {
    console.log('Unsubscribing...');
    Broadcaster.get().connector.unsubscribe(`private-user.${user.profile?.id}.notifications`);
    notificationChannel?.unbind('catalog-upload-completed');
    notificationChannel = null;
}

const user = useUserStore();

const list: Ref<Array<CatalogItem>> = ref([]);
const stats: Ref<CatalogStats | null> = ref(null);
const filters: Ref<CatalogFilters | null> = ref(null);
const itemsCount: Ref<number> = ref(0);

const processingCatalog = ref(false);


const showFilters = ref(false);
const showSorting = ref(false);

const activeCategoriesFilter: Ref<Array<string>> = ref([]);
const activeModelsFilters: Ref<Array<string>> = ref([]);
const activeBrandsFilters: Ref<Array<string>> = ref([]);
const activeGradesFilters: Ref<Array<string>> = ref([]);
const activeStorageFilters: Ref<Array<string>> = ref([]);
const activeSearchFilter: Ref<string> = ref('');
const filtersCount: Ref<number> = ref(0);

const alertMessage: Ref<AlertMessage | null> = ref(null);

let alertMessageKey = 0;
let page: number = 1;

const syncBasePrice = async (id: Number) => {
    alertMessageKey ++;

    try {
        const syncBasePriceResponse = await axios.patch('/api/catalog/' + id + '/sync-base-price');
        const catalogItem = syncBasePriceResponse.data as CatalogItem;

        const index = list.value.findIndex((item) => item.id === id);

        if (index === -1) {
            return;
        }

        list.value[index] = catalogItem;

        alertMessage.value = {
            type: 'success',
            message: 'Base price updated',
        }

    } catch (error) {

        alertMessage.value = {
            type: 'error',
            message: t('common.errors.somethingWrong')
        }

    }
}

const selectedCriteria = ref("grade:desc");
const sortingCriteria = [
    {label: "Highest grade", value: "grade:desc"},
    {label: "Lowest grade", value: "grade:asc"},
    {label: "Highest price", value: "price:desc"},
    {label: "Lowest price", value: "price:asc"},
    {label: "Highest stock", value: "stock:desc"},
    {label: "Lowest stock", value: "stock:asc"},
    {label: "Highest margin", value: "margin:desc"},
    {label: "Lowest margin", value: "margin:asc"},
];

const onSortingChange = async (event: Event) => {
    page = 1;
    await loadCatalog();
}

const updateFiltersCount = () => {
    filtersCount.value = activeCategoriesFilter.value.length +
        activeBrandsFilters.value.length +
        activeModelsFilters.value.length +
        activeGradesFilters.value.length +
        activeStorageFilters.value.length;
}

const getQuery = () => {
    const query: {
        categories?: string[];
        brands?: string[],
        models?: string[];
        grades?: string[];
        storage?: string[];
        search?: string,
        page?: number,
        sort?: string,
    } = {}

    query.page = page;
    query.sort = selectedCriteria.value;

    // Dropdowns
    if (activeCategoriesFilter.value.length) {
        query.categories = activeCategoriesFilter.value;
    }
    if (activeBrandsFilters.value.length) {
        query.brands = activeBrandsFilters.value;
    }
    if (activeModelsFilters.value.length) {
        query.models = activeModelsFilters.value;
    }
    if (activeGradesFilters.value.length) {
        query.grades = activeGradesFilters.value;
    }
    if (activeStorageFilters.value.length) {
        query.storage = activeStorageFilters.value;
    }

    // Text search
    if (activeSearchFilter.value) {
        query.search = activeSearchFilter.value;
    }

    return query;
}

const loadCatalog = async () => {
    try {
        const queryString = qs.stringify(getQuery())
        const catalogResponse = await axios.get<CatalogResponse>(`/api/catalog?${queryString}`) ;

        list.value = catalogResponse.data.items;
        stats.value = catalogResponse.data.stats;
        filters.value = catalogResponse.data.filters;
        itemsCount.value = catalogResponse.data.items_count;

    } catch (error) {
        console.error(error)
    }
}

const loadMore = async (event: Event) => {
    await loadMoreItems();
}

const loadMoreItems = async () => {
    try {
        page ++;

        const queryString = qs.stringify(getQuery());
        const itemsResponse = await axios.get(`/api/catalog?${queryString}`);

        list.value = list.value.concat(itemsResponse.data.items);

    } catch (error) {
        console.error(error);
    }
}

const onCategoryFilterChange = async (event: Event) => {
    page = 1;
    activeBrandsFilters.value = [];
    activeModelsFilters.value = [];

    updateFiltersCount();
    await loadCatalog();
}

const onBrandFilterChange = async (event: Event) => {
    page = 1;
    activeModelsFilters.value = [];


    updateFiltersCount();
    await loadCatalog();
}

const onModelsFilterChange = async (event: Event) => {
    page = 1;

    updateFiltersCount();
    await loadCatalog();
}

const onGradesFilterChange = async (event: Event) => {
    page = 1;

    updateFiltersCount();
    await loadCatalog();
}

const onStorageFilterChange = async (event: Event) => {
    page = 1;

    updateFiltersCount();
    await loadCatalog();
}

const onSearchInputChange = debounce(onModelsFilterChange, 500);

const closeFilters = () => {
   showFilters.value = false;
}

const openFilters = () => {
    showFilters.value = true;
    showSorting.value = false;
}

const closeSorting = () => {
    showSorting.value = false;
}

const openSorting = () => {
    showFilters.value = false;
    showSorting.value = true;
}

const resetFilters = () => {
    activeCategoriesFilter.value = [];
    activeBrandsFilters.value = [];
    activeModelsFilters.value = [];
    activeGradesFilters.value = [];
    activeStorageFilters.value = [];
    activeSearchFilter.value = '';

    updateFiltersCount();
}

const onResetFiltersBtnClick = async (event: Event) => {
    resetFilters();
    await loadCatalog();
}

const onProcessingCatalog = () => {
    console.log('onProcessingCatalog...');
    processingCatalog.value = true;

    notificationChannel?.bind('catalog-upload-completed', (data: {filename: string}) => {
        console.log('Event received', data);
        processingCatalog.value = false;
        updateCatalog();
    });
}

const updateCatalog = async () => {
    resetFilters();
    await loadCatalog();
}

const onProcessingError = () => {
    console.log('onProcessingError');
    processingCatalog.value = false;
}

const onProcessingSuccess = () => {
    console.log('onProcessingSuccess');
}

const downloadCatalog = async () => {
    window.location.href = `/api/catalog/export`;
}

onBeforeUnmount(() => unsubscribe());

subscribe();
loadCatalog();

</script>

<template>
    <h2 class="text-4xl font-bold text-white flex items-center">
        {{ t("catalog.title") }}

        <IntegrationTypeIcon :type="user.configuration?.integration_type || null" />
    </h2>

    <p class="my-4 text-lg text-gray-500">
        <span v-html='t("catalog.subtitle", {store: user.configuration?.integration_type, isActive: user.configuration?.active ? t("common.active") : t("common.inactive")})'/>
        <span v-if="user.configuration?.repricing_mode === 'dry_run' && user.configuration?.repricing_enabled" class="ml-4 text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-yellow-900 text-yellow-300">Dry run mode</span>
        <span class="block" v-if="user.configuration?.google_feed_url !== null" v-html="t('catalog.google_feed', {url: user.configuration?.google_feed_url})"/>
    </p>

    <CatalogUpload
        v-if="user.configuration?.manual_feed_upload_enabled"
        :processing="processingCatalog"
        @processing="onProcessingCatalog"
        @error="onProcessingError"
        @success="onProcessingSuccess"
    />

    <div class="mt-12 shadow-md">

        <!-- Header -->
        <div class="relative bg-gray-800 rounded-t-lg">

            <div class="flex justify-between items-end mx-4 px-4 py-8 border-b border-gray-600">

                <div class="flex flex-row space-x-12 text-white items-center">
                    <div>
                        <dt class="mb-1 text-3xl font-extrabold">{{ stats?.products }}</dt>
                        <dd class="text-xs font-bold text-gray-400 uppercase">{{ t('common.products') }}</dd>
                    </div>
                </div>

                <div>
                    <span class="text-gray-500 text-xs">{{ t('common.updatedAt', {updatedAt: stats?.last_update}) }}</span>
                </div>
            </div>

            <!-- Filters & Buttons -->
            <div class="flex flex-row justify-between items-center p-4">

                <div class="flex space-x-2">
                </div>


                <!-- Buttons -->
                <div class="flex flex-row gap-2 justify-between">

                    <div class="flex flex-row space-x-2 justify-between">

                        <div class="relative">
                            <button type="button" class="border font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center bg-gray-800 border-gray-700 text-white hover:bg-gray-700 me-2" @click.prevent="openFilters">

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="size-4 mr-2">
                                    <path d="M10 3.75a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM17.25 4.5a.75.75 0 0 0 0-1.5h-5.5a.75.75 0 0 0 0 1.5h5.5ZM5 3.75a.75.75 0 0 1-.75.75h-1.5a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 .75.75ZM4.25 17a.75.75 0 0 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5h1.5ZM17.25 17a.75.75 0 0 0 0-1.5h-5.5a.75.75 0 0 0 0 1.5h5.5ZM9 10a.75.75 0 0 1-.75.75h-5.5a.75.75 0 0 1 0-1.5h5.5A.75.75 0 0 1 9 10ZM17.25 10.75a.75.75 0 0 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5h1.5ZM14 10a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM10 16.25a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z" />
                                </svg>

                                Filter <span v-if="filtersCount" class="ml-2">({{filtersCount}})</span>
                            </button>

                            <div class="absolute w-56 top-14 right-0 z-10 rounded-lg bg-gray-800 shadow-xl border border-gray-700" v-if="showFilters" v-click-outside="closeFilters">
                                <div class="flex justify-between border-b items-center border-gray-700">
                                    <p class="text-white font-medium text-sm block py-2 px-4">Filters</p>

                                    <a href="#" @click.prevent="closeFilters">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" class="size-5 mr-3">
                                            <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
                                        </svg>
                                    </a>
                                </div>

                                <div class="p-3 flex flex-col space-y-2 items-start">
                                    <!-- Search Filter -->
                                    <div>
                                        <input
                                            v-model="activeSearchFilter"
                                            @input="onSearchInputChange"
                                            type="text"
                                            class="border border-gray-700 hover:border-gray-500 bg-gray-800 focus:outline-none focus:border-gray-300 text-white px-3 py-2 text-xs w-48 font-medium rounded-lg" :placeholder="t('common.filters.search')" />
                                    </div>

                                    <!-- Category Filter -->
                                    <DropdownFilter
                                        v-model:value="activeCategoriesFilter"
                                        :options="filters?.categories || []"
                                        @update:value="onCategoryFilterChange"
                                        :show="!!filters?.categories.length"
                                        :buttonLabel="t('common.filters.byCategory')"
                                        :optionsLabel="t('common.category')"
                                    />

                                    <!-- Brands Filter -->
                                    <DropdownFilter
                                        v-model:value="activeBrandsFilters"
                                        :options="filters?.brands || []"
                                        @update:value="onBrandFilterChange"
                                        :show="!!filters?.brands.length"
                                        :buttonLabel="t('common.filters.byBrand')"
                                        optionsLabel="Brands"
                                    />

                                    <!-- Models Filter -->
                                    <DropdownFilter
                                        v-model:value="activeModelsFilters"
                                        :options="filters?.models || []"
                                        @update:value="onModelsFilterChange"
                                        :show="!!filters?.models.length"
                                        :buttonLabel="t('common.filters.byModels')"
                                        :optionsLabel="t('common.models')"
                                    />

                                    <!-- Grades Filter -->
                                    <DropdownFilter
                                        v-model:value="activeGradesFilters"
                                        :options="filters?.grades || []"
                                        @update:value="onGradesFilterChange"
                                        :show="!!filters?.grades.length"
                                        :buttonLabel="t('common.filters.byGrade')"
                                        :optionsLabel="t('common.grades')"
                                    />

                                    <!-- Storage Filter -->
                                    <DropdownFilter
                                        v-model:value="activeStorageFilters"
                                        :options="filters?.storage || []"
                                        @update:value="onStorageFilterChange"
                                        :show="!!filters?.storage.length"
                                        :buttonLabel="t('common.filters.byStorage')"
                                        :optionsLabel="t('common.storage')"
                                    />

                                    <a href="#" class="inline-block pl-2 text-xs text-gray-300 underline" @click.prevent="onResetFiltersBtnClick">{{ t('common.filters.resetBtn') }}</a>
                                </div>
                            </div>
                        </div>

                        <div class="relative">
                            <button type="button" class="border font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center bg-gray-800 border-gray-700 text-white hover:bg-gray-700 me-2" @click="openSorting">

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="size-4 mr-2">
                                    <path fill-rule="evenodd" d="M2.24 6.8a.75.75 0 0 0 1.06-.04l1.95-2.1v8.59a.75.75 0 0 0 1.5 0V4.66l1.95 2.1a.75.75 0 1 0 1.1-1.02l-3.25-3.5a.75.75 0 0 0-1.1 0L2.2 5.74a.75.75 0 0 0 .04 1.06Zm8 6.4a.75.75 0 0 0-.04 1.06l3.25 3.5a.75.75 0 0 0 1.1 0l3.25-3.5a.75.75 0 1 0-1.1-1.02l-1.95 2.1V6.75a.75.75 0 0 0-1.5 0v8.59l-1.95-2.1a.75.75 0 0 0-1.06-.04Z" clip-rule="evenodd" />
                                </svg>

                                Sort by
                            </button>

                            <div class="absolute top-14 right-0 z-10 rounded-lg bg-gray-800 shadow-xl border border-gray-700" v-if="showSorting" v-click-outside="closeSorting">

                                <div class="flex justify-between border-b items-center border-gray-700">
                                    <p class="text-white font-medium text-sm block py-2 px-4">Sort by</p>

                                    <a href="#" @click.prevent="closeSorting">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" class="size-5 mr-3">
                                            <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
                                        </svg>
                                    </a>
                                </div>

                                <div class="p-3 flex flex-col space-y-2 items-start">
                                    <ul class="space-y-2 text-sm">
                                        <li class="flex items-center relative cursor-pointer" v-for="(criteria) in sortingCriteria">
                                            <input
                                                type="radio"
                                                name="sort"
                                                :id="criteria.value"
                                                :value="criteria.value"
                                                v-model="selectedCriteria"
                                                @change="onSortingChange"
                                                class="cursor-pointer peer appearance-none w-4 h-4 mr-1 rounded-full bg-gray-700 border border-gray-600 checked:bg-blue-600 checked:border-blue-600"
                                            />

                                            <label :for="criteria.value" class=" cursor-pointer flex items-center ml-2 text-xs font-medium text-gray-400 truncate ...">
                                                {{ criteria.label }}
                                            </label>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>

                    <button @click.prevent="downloadCatalog" v-if="user.configuration?.integration_type === 's3'" class="outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center text-gray-900 bg-gray-100 hover:bg-gray-200" type="button">
                        Download catalog

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4 ml-2">
                            <path d="M8.75 2.75a.75.75 0 0 0-1.5 0v5.69L5.03 6.22a.75.75 0 0 0-1.06 1.06l3.5 3.5a.75.75 0 0 0 1.06 0l3.5-3.5a.75.75 0 0 0-1.06-1.06L8.75 8.44V2.75Z" />
                            <path d="M3.5 9.75a.75.75 0 0 0-1.5 0v1.5A2.75 2.75 0 0 0 4.75 14h6.5A2.75 2.75 0 0 0 14 11.25v-1.5a.75.75 0 0 0-1.5 0v1.5c0 .69-.56 1.25-1.25 1.25h-6.5c-.69 0-1.25-.56-1.25-1.25v-1.5Z" />
                        </svg>

                    </button>
                </div>
            </div>

        </div>

        <!-- Table -->
        <div class="relative overflow-x-auto bg-gray-800">
            <table class="w-full text-sm text-left text-gray-400">
                <thead class="text-xs uppercase bg-gray-700 text-gray-400">
                    <tr>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.table.headings.id') }}</th>
                        <th scope="col" class="px-2 py-3">EAN</th>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.table.headings.product') }}</th>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.table.headings.storage') }}</th>
                        <th scope="col" class="px-2 py-3">RAM</th>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.table.headings.grade') }}</th>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.table.headings.color') }}</th>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.table.headings.battery') }}</th>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.table.headings.activePrice') }}</th>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.table.headings.basePrice') }}</th>
                        <th scope="col" class="px-2 py-3">Stock</th>
                        <th scope="col" class="px-2 py-3">Margin</th>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.table.headings.dynamicPrice') }}</th>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.table.headings.details') }}</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>

                    <tr v-for="(item, index) in list" class="border-gray-600 hover:bg-gray-700 border-b">

                        <td class="px-2 py-3">
                            <span  class="text-xs font-medium">{{ item.external_id }}</span>
                        </td>

                        <td class="px-2 py-3">
                            <span  class="text-xs font-medium">{{ item.ean }}</span>
                        </td>

                        <td class="px-2 py-3 text-xs font-medium text-white">
                            <div class="flex items-center">
                                <div class="max-w-[380px]">
                                    <span class="block">{{ item.name }}</span>
                                </div>

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4 ml-4 fill-yellow-400 shrink-0" v-if="item.notifications_count !== undefined && item.notifications_count > 0">
                                    <path d="M4.214 3.227a.75.75 0 00-1.156-.956 8.97 8.97 0 00-1.856 3.826.75.75 0 001.466.316 7.47 7.47 0 011.546-3.186zM16.942 2.271a.75.75 0 00-1.157.956 7.47 7.47 0 011.547 3.186.75.75 0 001.466-.316 8.971 8.971 0 00-1.856-3.826z" />
                                    <path fill-rule="evenodd" d="M10 2a6 6 0 00-6 6c0 1.887-.454 3.665-1.257 5.234a.75.75 0 00.515 1.076 32.94 32.94 0 003.256.508 3.5 3.5 0 006.972 0 32.933 32.933 0 003.256-.508.75.75 0 00.515-1.076A11.448 11.448 0 0116 8a6 6 0 00-6-6zm0 14.5a2 2 0 01-1.95-1.557 33.54 33.54 0 003.9 0A2 2 0 0110 16.5z" clip-rule="evenodd" />
                                </svg>
                            </div>
                        </td>

                        <td class="px-2 py-3 text-xs font-medium">
                            {{ item.storage }}
                        </td>

                        <td class="px-2 py-3 text-xs font-medium">
                            {{ item.ram ?? '-' }}
                        </td>

                        <td class="px-2 py-3">
                            <Grade :grade="item.grade" />
                        </td>

                        <td class="px-2 py-3 capitalize">
                            {{ item.color ?? '-' }}
                        </td>

                        <td class="px-2 py-3">
                            <Battery :status="item.battery_type" />
                        </td>

                        <td class="px-2 py-3 font-medium text-white">
                                <span v-if="item.price">{{ item.currency_symbol }} {{ item.price }}</span>
                                <span v-else>-</span>
                        </td>

                        <td class="px-2 py-3 font-medium text-white">
                            <div class="flex items-center w-24">
                                <span v-if="item.base_price">{{ item.currency_symbol }} {{ item.base_price }}</span>
                                <span v-else>-</span>

                                <button class="inline-flex text-green-500 hover:text-green-300 ml-2" @click="syncBasePrice(item.id)"  v-if="item.price && item.base_price && (item.price < item.base_price)">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4">
                                        <path fill-rule="evenodd" d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z" clip-rule="evenodd" />
                                    </svg>
                                </button>

                            </div>
                        </td>

                        <td class="px-2 py-3 font-medium text-white">
                            <span v-if="item.stock">{{ item.stock}}</span>
                            <span v-else>-</span>
                        </td>

                        <td class="px-2 py-3 font-medium text-white">
                            <span v-if="item.margin_percentage">{{ item.margin_percentage}}%</span>
                            <span v-else>-</span>
                        </td>

                        <td class="px-2 py-3">
                            <span v-if="!item.automatic_price_enabled" class="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{{ t('catalog.table.inactiveDynamicPrice') }}</span>
                            <span v-else class="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">{{ t('catalog.table.activeDynamicPrice') }}</span>

                        </td>

                        <td class="px-2 py-3">
                            <router-link :to="{ name: 'dashboard.catalog.detail', params: { id: item.id }}" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">{{ t('catalog.table.more') }}</router-link>
                        </td>
                    </tr>
                </tbody>

            </table>
        </div>

        <div class="relative overflow-hidden rounded-b-lg bg-gray-800">
            <nav class="flex flex-row items-center justify-between p-4" aria-label="Table navigation">
                <div class="text-sm">
                    <p class="font-semibold text-white">
                        {{ list.length }} <span class="font-normal text-gray-400">{{ t('common.of') }}</span> {{ itemsCount }}
                    </p>
                </div>
                <button type="button" class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-blue-700 hover:bg-blue-800" @click="loadMore" v-if="list.length < itemsCount">
                    Load more
                </button>
            </nav>
        </div>
    </div>

    <Alert :message="alertMessage" />

</template>
