<script setup lang="ts">

import {onBeforeRouteUpdate} from "vue-router";
import qs from "qs";
import axios from "axios";
import {ref, Ref} from "vue";
import {CatalogFilters, Filter, Item, Vendor} from "../types";
import {debounce, find} from "lodash";
import {useUserStore} from "../stores/UserStore";
import Grade from "./Grade.vue";
import Flag from "./Flag.vue";
import DropdownFilter from "./DropdownFilter.vue";
import {useI18n} from "vue-i18n";
const {t} = useI18n();

let page: number = 1;

// Reactive
const vendor: Ref<Vendor|null> = ref(null);
const list: Ref<Array<Item>> = ref([]);
const itemsCount: Ref<number> = ref(0);
const lastUpdate: Ref<string> = ref('');
const filters: Ref<CatalogFilters | null> = ref(null);

const showFilters = ref(false);

const activeModelsFilters: Ref<Array<string>> = ref([]);
const activeBrandsFilters: Ref<Array<string>> = ref([]);
const activeGradesFilters: Ref<Array<string>> = ref([]);
const activeStorageFilters: Ref<Array<string>> = ref([]);
const activeSearchFilter: Ref<string> = ref('');
const filtersCount: Ref<number> = ref(0);

const closeFilters = () => {
    showFilters.value = false;
}

const openFilters = () => {
    showFilters.value = true;
}

const updateFiltersCount = () => {
    filtersCount.value =
        activeBrandsFilters.value.length +
        activeModelsFilters.value.length +
        activeGradesFilters.value.length +
        activeStorageFilters.value.length;
}

const resetFilters = () => {
    activeModelsFilters.value = [];
    activeBrandsFilters.value = [];
    activeGradesFilters.value = [];
    activeStorageFilters.value = [];
    activeSearchFilter.value = '';
    page = 1;

    updateFiltersCount();
}


// Const
const user = useUserStore();


// Props
const props = defineProps<{
    id: number
}>();

const loadVendorInformation = (id: number) => {
    const selectedVendor = find(user.profile?.vendors, {'id': Number(id)});

    if (selectedVendor === undefined || selectedVendor === null) {
        throw new Error('Something went wrong');
    }

    vendor.value = selectedVendor as Vendor;
}

const getQuery = () => {
    const query: {
        models?: string[],
        products?: string[],
        page?: number,
        brands?: string[],
        grades?: string[],
        storage?: string[],
        search?: string,
    } = {}

    if (activeModelsFilters.value.length) {
        query.models = activeModelsFilters.value
    }

    if (activeBrandsFilters.value.length) {
        query.brands = activeBrandsFilters.value
    }

    if (activeGradesFilters.value.length) {
        query.grades = activeGradesFilters.value
    }

    if (activeStorageFilters.value.length) {
        query.storage = activeStorageFilters.value
    }

    if (activeSearchFilter.value) {
        query.search = activeSearchFilter.value;
    }

    query.page = page;

    return query;
}

const loadItems = async (id: number) => {
    try {
        list.value = [];

        const queryString = qs.stringify(getQuery());
        const itemsResponse = await axios.get(`/api/competitors/${id}?${queryString}`);

        list.value = itemsResponse.data.items;
        itemsCount.value = itemsResponse.data.items_count;
        lastUpdate.value = itemsResponse.data.last_update;
        filters.value = itemsResponse.data.filters;
    } catch (error) {
        console.error(error);
    }
}

const loadMore = async (event: Event) => {
    await loadMoreItems(props.id);
}

const onModelsFilterChange = async (event: Event) => {
    page = 1;

    updateFiltersCount();
    await loadItems(props.id);
}

const onProductsFilterChange = async (event: Event) => {
    page = 1;

    updateFiltersCount();
    await loadItems(props.id);
}

const onBrandsFilterChange = async (event: Event) => {
    activeModelsFilters.value = [];

    page = 1;

    updateFiltersCount();
    await loadItems(props.id);
}

const onGradesFilterChange = async (event: Event) => {
    page = 1;

    updateFiltersCount();
    await loadItems(props.id);
}

const onStorageFilterChange = async (event: Event) => {
    page = 1;

    updateFiltersCount();
    await loadItems(props.id);
}

const onSearchInputChange = debounce(onProductsFilterChange, 500);

const loadMoreItems = async (id: number) => {
    try {
        page ++;

        const queryString = qs.stringify(getQuery());
        const itemsResponse = await axios.get(`/api/competitors/${id}?${queryString}`);

        list.value = list.value.concat(itemsResponse.data.items);

    } catch (error) {
        console.error(error);
    }
}

const onResetFiltersBtnClick = async (event: Event) => {
    resetFilters();
    await loadItems(props.id);
}

onBeforeRouteUpdate(async (to, from) => {
    if (to.params.id !== from.params.id) {
        resetFilters();
        loadVendorInformation(Number(to.params.id));
        await loadItems(Number(to.params.id));
    }
});

loadVendorInformation(props.id);
loadItems(props.id);

</script>

<template>
    <h2 class="text-4xl mb-4 font-bold text-white flex items-center">
        <span class="block mr-4">{{ vendor?.name }}</span>

        <Flag :country="vendor?.country || ''" :show="true" :size="25" />
    </h2>

    <div class="mt-12 shadow-md">

        <!-- Header -->
        <div class="relative bg-gray-800 rounded-t-lg">

            <div class="flex justify-between items-end mx-4 px-4 py-8 border-b border-gray-600">
                <div class="flex flex-row space-x-12 text-white items-center">
                    <div>
                        <dt class="mb-1 text-3xl font-extrabold">{{ itemsCount }}</dt>
                        <dd class="text-xs font-bold text-gray-400 uppercase">{{ vendor?.category.name }}</dd>
                    </div>
                </div>

                <div>
                    <span class="text-gray-500 text-xs">{{ t('common.updatedAt', {updatedAt: lastUpdate}) }}</span>
                </div>
            </div>


            <!-- Filters & Buttons -->
            <div class="flex flex-row-reverse justify-between items-center p-4 ">

                <div class="flex space-x-2">

                    <div class="relative">
                        <button type="button" class="border font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center bg-gray-800 border-gray-700 text-white hover:bg-gray-700 me-2" @click="openFilters">

                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="size-4 mr-2">
                                <path d="M10 3.75a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM17.25 4.5a.75.75 0 0 0 0-1.5h-5.5a.75.75 0 0 0 0 1.5h5.5ZM5 3.75a.75.75 0 0 1-.75.75h-1.5a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 .75.75ZM4.25 17a.75.75 0 0 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5h1.5ZM17.25 17a.75.75 0 0 0 0-1.5h-5.5a.75.75 0 0 0 0 1.5h5.5ZM9 10a.75.75 0 0 1-.75.75h-5.5a.75.75 0 0 1 0-1.5h5.5A.75.75 0 0 1 9 10ZM17.25 10.75a.75.75 0 0 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5h1.5ZM14 10a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM10 16.25a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z" />
                            </svg>

                            Filter <span v-if="filtersCount" class="ml-2">({{filtersCount}})</span>
                        </button>

                        <!-- Filters -->
                        <div class="absolute w-56 top-14 right-0 z-10 rounded-lg bg-gray-800 shadow-xl border border-gray-700" v-if="showFilters" v-click-outside="closeFilters">

                            <div class="flex justify-between border-b items-center border-gray-700">
                                <p class="text-white font-medium text-sm block py-2 px-4">Filters</p>

                                <a href="#" @click.prevent="closeFilters">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" class="size-5 mr-3">
                                        <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
                                    </svg>
                                </a>
                            </div>

                            <div class="p-3 flex flex-col space-y-2 items-start">

                                <!-- Search Filter -->
                                <div>
                                    <input
                                        v-model="activeSearchFilter"
                                        @input="onSearchInputChange"
                                        type="text"
                                        class="border border-gray-700 hover:border-gray-500 bg-gray-800 focus:outline-none focus:border-gray-300 text-white px-3 py-2 text-xs w-48 font-medium rounded-lg" :placeholder="t('common.filters.search')" />
                                </div>

                                <!-- Brands Filter -->
                                <DropdownFilter
                                    v-model:value="activeBrandsFilters"
                                    :options="filters?.brands || []"
                                    @update:value="onBrandsFilterChange"
                                    :show="!!filters?.brands.length"
                                    :buttonLabel="t('common.filters.byBrand')"
                                    optionsLabel="Brands"
                                />

                                <!-- Models Filter -->
                                <DropdownFilter
                                    v-model:value="activeModelsFilters"
                                    :options="filters?.models || []"
                                    @update:value="onModelsFilterChange"
                                    :show="!!filters?.models.length"
                                    :buttonLabel="t('common.filters.byModels')"
                                    :optionsLabel="t('common.models')"
                                />

                                <!-- Grades Filter -->
                                <DropdownFilter
                                    v-model:value="activeGradesFilters"
                                    :options="filters?.grades || []"
                                    @update:value="onGradesFilterChange"
                                    :show="!!filters?.grades.length"
                                    :buttonLabel="t('common.filters.byGrade')"
                                    :optionsLabel="t('common.grades')"
                                />

                                <!-- Storage Filter -->
                                <DropdownFilter
                                    v-model:value="activeStorageFilters"
                                    :options="filters?.storage || []"
                                    @update:value="onStorageFilterChange"
                                    :show="!!filters?.storage.length"
                                    :buttonLabel="t('common.filters.byStorage')"
                                    :optionsLabel="t('common.storage')"
                                />

                                <a href="#" class="inline-block pl-2 text-xs text-gray-300 underline" @click.prevent="onResetFiltersBtnClick">{{ t('common.filters.resetBtn') }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Table -->
        <div class="relative overflow-x-auto bg-gray-800">
            <table class="w-full text-sm text-left text-gray-400">
                <thead class="text-xs uppercase bg-gray-700 text-gray-400">
                <tr>
                    <th scope="col" class="px-2 py-3">{{ t('competitor.table.headings.name') }}</th>
                    <th scope="col" class="px-2 py-3">{{ t('competitor.table.headings.catalogItems') }}</th>
                    <th scope="col" class="px-2 py-3">{{ t('competitor.table.headings.grade') }}</th>
                    <th scope="col" class="px-2 py-3">{{ t('competitor.table.headings.price') }}</th>
                    <th scope="col" class="px-2 py-3">{{ t('competitor.table.headings.storage') }}</th>
                    <th scope="col" class="px-2 py-3">{{ t('competitor.table.headings.color') }}</th>
                    <th scope="col" class="px-2 py-3">{{ t('competitor.table.headings.battery') }}</th>
                    <th scope="col" class="px-2 py-3">{{ t('competitor.table.headings.reseller') }}</th>
                    <th scope="col" class="px-2 py-3">{{ t('competitor.table.headings.details') }}</th>
                    <th></th>
                </tr>
                </thead>

                <tbody>

                <tr v-for="(item, index) in list" class="border-gray-600 hover:bg-gray-700 border-b">

                    <td class="px-2 py-3 font-medium text-white">
                        <div class="max-w-[380px]">
                            <span class="block truncate">{{ item.name }}</span>
                        </div>
                    </td>

                    <td class="px-2 font-normal whitespace-nowrap text-white">
                        <div class="py-2">
                            <div v-for="(catalogItem, index) in item.catalog_items" class="flex items-center py-1 text-xs text-gray-600">
                                <p class="mr-4 w-32">EAN: <router-link :to="{ name: 'dashboard.catalog.detail', params: { id: catalogItem.id }}" class="text-white hover:underline">{{catalogItem.ean}}</router-link></p>
                                <p class="mr-4">ID: <router-link :to="{ name: 'dashboard.catalog.detail', params: { id: catalogItem.id }}" class="text-white hover:underline">{{catalogItem.external_id}}</router-link></p>
                            </div>
                        </div>
                    </td>

                    <td class="px-2 py-3">
                        <Grade :grade="item.grade" />
                    </td>

                    <td class="px-2 py-3 font-medium text-white">
                        {{ item.currency_symbol }} {{ item.price }}
                    </td>

                    <td class="px-2 py-3 text-xs font-medium">
                        {{ item.storage }}
                    </td>

                    <td class="px-2 py-3 capitalize text-xs font-medium">
                        {{ item.color ?? '-' }}
                    </td>

                    <td class="px-2 py-3 capitalize text-xs font-medium">
                        {{ item.battery ?? 'Standard' }}
                    </td>

                    <td class="px-2 py-3 text-xs text-gray-300">
                        {{ item.seller_name }}
                    </td>

                    <td class="px-2 py-3">
                        <router-link :to="{ name: 'dashboard.competitor.items.detail', params: { id: props.id, itemId: item.id }}" class="font-medium text-blue-500 hover:underline">{{ t('competitor.table.more') }}</router-link>
                    </td>

                </tr>


                </tbody>
            </table>
        </div>

        <!-- Load more -->
        <div class="relative overflow-hidden rounded-b-lg bg-gray-800">
            <nav class="flex flex-row items-center justify-between p-4" aria-label="Table navigation">
                <div class="text-sm">
                    <p class="font-semibold text-white">
                        {{ list.length }} <span class="font-normal text-gray-400">{{ t('common.of') }}</span> {{ itemsCount }}
                    </p>
                </div>
                <button type="button" class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-blue-700 hover:bg-blue-800" @click="loadMore" v-if="list.length < itemsCount">
                    Load more
                </button>
            </nav>
        </div>
    </div>
</template>
