<script setup lang="ts">

import {onUpdated, toRef} from "vue";

const props = defineProps<{
    show: number;
}>();

const show = toRef(props, 'show');

onUpdated(() => {
    window.setTimeout(() => {
        show.value = 0;
    }, 2500);
});


</script>


<template>

    <transition enter-from-class="translate-x-10 opacity-0" enter-to-class="translate-x-0 opacity-100" leave-from-class="translate-x-0 opacity-100" leave-to-class="translate-x-6 opacity-0" >

        <div v-if="show > 0" class="transition-all duration-500 ease-in-out shadow fixed top-20 right-5 flex items-center w-full max-w-sm p-4 space-x-4 text-gray-500 bg-white rounded-lg dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 border border-gray-600" role="alert">
            <div class="ml-3 text-sm font-normal ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="size-8 mb-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 2.994v2.25m10.5-2.25v2.25m-14.252 13.5V7.491a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v11.251m-18 0a2.25 2.25 0 0 0 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25m-18 0v-7.5a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v7.5m-6.75-6h2.25m-9 2.25h4.5m.002-2.25h.005v.006H12v-.006Zm-.001 4.5h.006v.006h-.006v-.005Zm-2.25.001h.005v.006H9.75v-.006Zm-2.25 0h.005v.005h-.006v-.005Zm6.75-2.247h.005v.005h-.005v-.005Zm0 2.247h.006v.006h-.006v-.006Zm2.25-2.248h.006V15H16.5v-.005Z" />
                </svg>

                <p class="text-white text-xl font-medium">Want access to more data?</p>
                <p>Contact us at <a href="mailto:support@recommerceiq.com" class="text-sm inline-flex items-center font-medium text-blue-600 dark:text-gray-500 hover:dark:text-white transition-all">support@recommerceiq.com</a>!</p>
            </div>
        </div>

    </transition>

</template>
