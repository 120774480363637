<script setup lang="ts">
import {Chart, ChartConfiguration} from 'chart.js/auto';
import {ref, Ref} from "vue";
import dayjs from 'dayjs';
import axios from "axios";
import {AlertMessage, CatalogItem, CatalogItemSnapshot, Notification, PriceResponse} from "../types";
import {onBeforeRouteUpdate} from "vue-router";
import {forEach, groupBy, join, last, slice, split} from "lodash";
import Alert from "./Alert.vue";
import {useUserStore} from "../stores/UserStore";
import Grade from "./Grade.vue";
import Flag from "./Flag.vue";
import {useI18n} from "vue-i18n";
import MoreInfo from "./MoreInfo.vue";

const {t} = useI18n();
const resellChart = ref();
const buybackChart = ref();
const userStore = useUserStore();

const showMoreInfoPanel: Ref<number> = ref(0);
const data: Ref<PriceResponse | null> = ref(null);
const alertMessage: Ref<AlertMessage | null> = ref(null);
const type: Ref<String | null | undefined> = ref(userStore.configuration?.catalog_type);

let showMinPriceReachedAlert: boolean = false;
let showMaxPriceReachedAlert: boolean = false;

let alertMessageKey = 0;

const props = defineProps<{
    id: number
}>();

const loadPrices = async (id: number) => {
    try {
        const priceResponse = await axios.get('/api/catalog/' + id);
        data.value = priceResponse.data as PriceResponse;

        showMinPriceReachedAlert = shouldShowMinPriceReachedAlert();
        showMaxPriceReachedAlert = shouldShowMaxPriceReachedAlert();

        await renderResellChart(priceResponse.data);
        await renderBuybackChart(priceResponse.data);
    } catch (error) {
    }
}

const shouldShowMinPriceReachedAlert = (): boolean => {
    if (data.value?.detail.has_prices === false) {
        return false;
    }

    if (isNotificationContainingType("min_allowed_price_reached", data.value?.detail.notifications || [])) {
        return true;
    }

    return data.value?.detail.price === data.value?.prices.min;
}

const shouldShowMaxPriceReachedAlert = (): boolean => {
    if (data.value?.detail.has_prices === false) {
        return false;
    }

    if (isNotificationContainingType("max_allowed_price_reached", data.value?.detail.notifications || [])) {
        return true;
    }

    return data.value?.detail.price === data.value?.prices.max;
}


const isNotificationContainingType = (notificationType: string, notifications: Notification[]): boolean => {
    return notifications.some((item: Notification) => {
        return item.type === notificationType
    });
}

const toggleDynamicPrice = async (event: Event) => {
    alertMessageKey ++;

    try {
        const toggleDynamicPriceResponse = await axios.put('/api/catalog/' + props.id + '/toggle-dynamic-price');
        const catalogItem = toggleDynamicPriceResponse.data as CatalogItem;

        if (data.value === null) {
            return;
        }

        data.value.detail.automatic_price_enabled = catalogItem.automatic_price_enabled;

        let message = '';
        switch (catalogItem.automatic_price_enabled) {
            case true:
                message = t('catalog.details.automaticPricingMessage.enabled');
                break;

            case false:
                message = t('catalog.details.automaticPricingMessage.disabled');
                break;
        }

        alertMessage.value = {
            type: 'success',
            message: message,
        }

    } catch (error) {

        alertMessage.value = {
            type: 'error',
            message: t('common.errors.somethingWrong')
        }

    }
}

const renderResellChart = async (d: PriceResponse) => {

    if (d.data.resell === null) {
        return;
    }

    const datasets: {
        label: string;
        data: { x: number; y: number; }[],
        pointRadius?: number
    }[] = [];

    const groupedData = groupBy(d.data.resell?.historical, 'vendor_name');

    forEach(groupedData, (prices, vendor) => {

        const vendorPrices: { x: number; y: number; }[] = [];

        forEach(prices, (price) => {
            vendorPrices.push({
                x: dayjs(price.date).unix(),
                y: price.median_price,
            })
        });

        datasets.push({
            label: vendor + ' ' + prices[0].currency_symbol,
            data: vendorPrices,
        });

    });

    const snapshotPrices: { x: number; y: number; }[] = [];

    forEach(d.data.resell?.snapshots, (snapshot: CatalogItemSnapshot) => {
        snapshotPrices.push({
            x: dayjs(snapshot.created_at).unix(),
            y: snapshot.price,
        })
    });

    if (snapshotPrices.length > 0) {
        datasets.push({
            label: userStore.profile?.name || '' + ' ' + d.detail.currency_symbol,
            data: snapshotPrices,
            pointRadius: 7
        });
    }

    // Chart configuration
    const config = {
        type: 'scatter',
        data: {
            datasets: datasets,
        },

        options: {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                    title: {
                        color: 'rgba(156, 163, 175, 1)',
                    }
                },
                tooltip: {
                    callbacks: {
                        title: function (items: any) {
                            const pieces = split(items[0].dataset.label, " ");
                            const label = join(slice(pieces, 0, pieces.length - 1), " ");
                            const day = dayjs.unix(Number(items[0].parsed.x)).format('DD/MM');
                            return label + " " + day;
                        },
                        label: function (items: any) {
                            const currencySymbol = last(split(items.dataset.label, " "));
                            const price = items.parsed.y;
                            return currencySymbol + " " + price;
                        },
                    }
                }
            },
            scales: {
                y: {
                    grid: {
                        color: 'rgba(255, 255, 255, 0.1)'
                    },
                    ticks: {
                        callback: function(value: any, index: any, ticks: any) {
                            return value;
                        },
                        color: 'rgba(156, 163, 175, 1)',
                    },
                },
                x: {
                    grid: {
                        color: 'rgba(255, 255, 255, 0.1)'
                    },
                    ticks: {
                        callback: function (value: any, index: any, ticks: any) {
                            return dayjs.unix(Number(value)).format('DD/MM');
                        },
                        color: 'rgba(156, 163, 175, 1)',
                    }
                },
            },
        },
    };

    new Chart(
        resellChart.value,
        config as ChartConfiguration
    );
}

const showBuybackData = (event: MouseEvent) => {
    if (! userStore.extra?.buyback_enabled) {
        showMoreInfoPanel.value ++;
        return;
    }

    type.value = 'buyback';
};

const showResellData = (event: MouseEvent) => {
    if (! userStore.extra?.resell_enabled) {
        showMoreInfoPanel.value ++;
        return;
    }

    type.value = 'resell';
};

const renderBuybackChart = async (data: PriceResponse) => {

    if (data.data.buyback === null) {
        return;
    }

    const datasets: {
        label: string;
        data: { x: number; y: number; }[],
        pointRadius?: number
    }[] = [];

    const groupedData = groupBy(data.data.buyback?.historical, 'vendor_name');

    forEach(groupedData, (prices, vendor) => {

        const vendorPrices: { x: number; y: number; }[] = [];

        forEach(prices, (price) => {
            vendorPrices.push({
                x: dayjs(price.date).unix(),
                y: price.median_price,
            })
        });

        datasets.push({
            label: vendor + ' ' + prices[0].currency_symbol,
            data: vendorPrices,
        });

    });

    const snapshotPrices: { x: number; y: number; }[] = [];

    forEach(data.data.buyback?.snapshots, (snapshot: CatalogItemSnapshot) => {
        snapshotPrices.push({
            x: dayjs(snapshot.created_at).unix(),
            y: snapshot.price,
        })
    });

    if (snapshotPrices.length > 0) {
        datasets.push({
            label: userStore.profile?.name || '' + ' ' + data.detail.currency_symbol,
            data: snapshotPrices,
            pointRadius: 7
        });
    }

    // Chart configuration
    const config = {
        type: 'scatter',
        data: {
            datasets: datasets,
        },

        options: {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                    title: {
                        color: 'rgba(156, 163, 175, 1)',
                    }
                },
                tooltip: {
                    callbacks: {
                        title: function (items: any) {
                            const pieces = split(items[0].dataset.label, " ");
                            const label = join(slice(pieces, 0, pieces.length - 1), " ");
                            const day = dayjs.unix(Number(items[0].parsed.x)).format('DD/MM');
                            return label + " " + day;
                        },
                        label: function (items: any) {
                            const currencySymbol = last(split(items.dataset.label, " "));
                            const price = items.parsed.y;
                            return currencySymbol + " " + price;
                        },
                    }
                }
            },
            scales: {
                y: {
                    grid: {
                        color: 'rgba(255, 255, 255, 0.1)'
                    },
                    ticks: {
                        callback: function(value: any, index: any, ticks: any) {
                            return value;
                        },
                        color: 'rgba(156, 163, 175, 1)',
                    },
                },
                x: {
                    grid: {
                        color: 'rgba(255, 255, 255, 0.1)'
                    },
                    ticks: {
                        callback: function (value: any, index: any, ticks: any) {
                            return dayjs.unix(Number(value)).format('DD/MM');
                        },
                        color: 'rgba(156, 163, 175, 1)',
                    }
                },
            },
        },
    };

    new Chart(
        buybackChart.value,
        config as ChartConfiguration
    );
}

onBeforeRouteUpdate(async (to, from) => {
    if (to.params.id !== from.params.id) {
        await loadPrices(Number(to.params.id));
    }
});

loadPrices(props.id);

</script>

<template>
    <router-link to="/dashboard/catalog"  class="mb-4 inline-flex items-center font-medium hover:text-gray-400 text-gray-500 transition-colors">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 mr-1">
            <path fill-rule="evenodd"  d="M20.25 12a.75.75 0 01-.75.75H6.31l5.47 5.47a.75.75 0 11-1.06 1.06l-6.75-6.75a.75.75 0 010-1.06l6.75-6.75a.75.75 0 111.06 1.06l-5.47 5.47H19.5a.75.75 0 01.75.75z" clip-rule="evenodd"/>
        </svg>
        {{ t('catalog.details.backToCatalog') }}
    </router-link>

    <h2 class="text-xl font-bold dark:text-white" v-if="data">
        {{ data.detail.name }}

        <a :href="data.detail.url" target="_blank" class="inline-flex items-center ml-3 px-3 py-2 text-xs font-medium text-center text-white rounded-lg dark:bg-blue-600 dark:hover:bg-blue-700" v-if="data.detail.url">

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4 mr-2">
                <path fill-rule="evenodd" d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z" clip-rule="evenodd" />
                <path fill-rule="evenodd" d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z" clip-rule="evenodd" />
            </svg>

            {{ t('catalog.details.open') }}
        </a>
    </h2>

    <div class="flex mb-8 text-gray-400 text-xs mt-2 space-x-6 divide-x divide-slate-600" v-if="data">
        <div>
            <span class="capitalize">{{ t('common.grade') }}</span>
            <p class="text-gray-200 font-bold">{{ data.detail.grade }}</p>
        </div>

        <div class="pl-2" v-for="(value, name) in data.detail.details ">
            <span class="capitalize">{{ name }}</span>
            <p class="text-gray-200 font-bold uppercase">{{ value }}</p>
        </div>

    </div>

    <div id="alert-4" class="flex p-4 mb-4 text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 max-w-2xl items-center" role="alert" v-if="showMinPriceReachedAlert || showMaxPriceReachedAlert">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4 mt-1">
            <path d="M4.214 3.227a.75.75 0 00-1.156-.956 8.97 8.97 0 00-1.856 3.826.75.75 0 001.466.316 7.47 7.47 0 011.546-3.186zM16.942 2.271a.75.75 0 00-1.157.956 7.47 7.47 0 011.547 3.186.75.75 0 001.466-.316 8.971 8.971 0 00-1.856-3.826z" />
            <path fill-rule="evenodd" d="M10 2a6 6 0 00-6 6c0 1.887-.454 3.665-1.257 5.234a.75.75 0 00.515 1.076 32.94 32.94 0 003.256.508 3.5 3.5 0 006.972 0 32.933 32.933 0 003.256-.508.75.75 0 00.515-1.076A11.448 11.448 0 0116 8a6 6 0 00-6-6zm0 14.5a2 2 0 01-1.95-1.557 33.54 33.54 0 003.9 0A2 2 0 0110 16.5z" clip-rule="evenodd" />
        </svg>

        <div class="ml-3 text-sm font-medium">
            <span v-if="showMinPriceReachedAlert">
                {{ t('catalog.details.thresholdPriceReached.minimum') }}
            </span>

            <span v-if="showMaxPriceReachedAlert">
                {{ t('catalog.details.thresholdPriceReached.maximum') }}
            </span>
        </div>
    </div>

    <div class="flex flex-row items-start text-white mb-12 space-x-4" >

        <div class="w-2/3 ">

            <!-- Prices -->
            <div v-if="data" class="mb-4 bg-gray-800 sm:rounded-lg p-4">

                <!-- Header -->
                <div class="flex items-center justify-between">
                    <h5 class="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{{ t('catalog.details.pricesTab.title') }}</h5>

                    <div class="flex items-center" v-if="data?.detail.automatic_price_enabled">
                        <span class=" mr-2 text-xs font-medium px-2.5 py-2 rounded-lg bg-green-900 text-green-300 inline-flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 mr-2">
                              <path fill-rule="evenodd"  d="M12 1.5a.75.75 0 01.75.75V4.5a.75.75 0 01-1.5 0V2.25A.75.75 0 0112 1.5zM5.636 4.136a.75.75 0 011.06 0l1.592 1.591a.75.75 0 01-1.061 1.06l-1.591-1.59a.75.75 0 010-1.061zm12.728 0a.75.75 0 010 1.06l-1.591 1.592a.75.75 0 01-1.06-1.061l1.59-1.591a.75.75 0 011.061 0zm-6.816 4.496a.75.75 0 01.82.311l5.228 7.917a.75.75 0 01-.777 1.148l-2.097-.43 1.045 3.9a.75.75 0 01-1.45.388l-1.044-3.899-1.601 1.42a.75.75 0 01-1.247-.606l.569-9.47a.75.75 0 01.554-.68zM3 10.5a.75.75 0 01.75-.75H6a.75.75 0 010 1.5H3.75A.75.75 0 013 10.5zm14.25 0a.75.75 0 01.75-.75h2.25a.75.75 0 010 1.5H18a.75.75 0 01-.75-.75zm-8.962 3.712a.75.75 0 010 1.061l-1.591 1.591a.75.75 0 11-1.061-1.06l1.591-1.592a.75.75 0 011.06 0z"  clip-rule="evenodd"/>
                            </svg>
                            {{ t('catalog.details.pricesTab.dynamicPriceLabel.active') }}
                        </span>

                        <button type="button" class="text-white font-medium rounded-lg text-xs px-3 py-2  bg-gray-700 hover:bg-gray-600" @click="toggleDynamicPrice">
                            {{ t('catalog.details.pricesTab.dynamicPriceButton.disable') }}
                        </button>
                    </div>


                    <div class="flex items-center" v-else>
                        <span class=" mr-2 bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-2 rounded-lg dark:bg-gray-700 dark:text-gray-300 inline-flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 mr-2">
                              <path fill-rule="evenodd"  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"  clip-rule="evenodd"/>
                            </svg>
                            {{ t('catalog.details.pricesTab.dynamicPriceLabel.inactive') }}
                        </span>

                        <button type="button" class="text-white font-medium rounded-lg text-xs px-3 py-2 bg-green-600 hover:bg-green-700" @click="toggleDynamicPrice">
                             {{ t('catalog.details.pricesTab.dynamicPriceButton.enable') }}
                        </button>
                    </div>
                </div>


                <div class="flex flex-col justify-between">

                    <!-- Suggested price and market price -->
                    <div class="flex justify-between items-center mt-2">
                        <div class="font-light dark:text-gray-400">{{ t('catalog.details.pricesTab.suggestedPrice') }}</div>
                        <div class="mb-1 text-2xl font-bold" v-if="data.prices.suggested !== null">{{ data.detail.currency_symbol }} {{ data.prices.suggested }}</div>
                        <div class="mb-1 text-2xl font-bold" v-if="data.prices.suggested === null">-</div>
                    </div>

                    <div class="flex justify-between items-center mt-2 text-sm">
                        <div class="font-light dark:text-gray-400">{{ t('catalog.details.pricesTab.recommercePrice') }}</div>
                        <div class="mb-1" v-if="data.prices.market_price !== null">{{ data.detail.currency_symbol }} {{ data.prices.market_price }}</div>
                        <div class="mb-1" v-if="data.prices.market_price === null">-</div>
                    </div>

                    <!-- Item prices -->
                    <div class="mt-4 border-gray-700 border-t pt-4" v-if="data.detail.has_prices">
                        <div class="flex justify-between items-center">
                            <div class="font-light text-gray-400">{{ t('catalog.details.pricesTab.activePrice') }}</div>
                            <div class="mb-1 text-2xl font-extrabold">{{ data.detail.currency_symbol }} {{ data.detail.price }}</div>
                        </div>

                        <div class="flex justify-between items-center text-sm">
                            <div class="font-light text-gray-400">{{ t('catalog.details.pricesTab.basePrice') }}</div>
                            <div>{{ data.detail.currency_symbol }} {{ data.detail.base_price }}</div>
                        </div>

                        <div class="flex justify-between items-center mt-1 text-sm" v-if="data.prices?.min">
                            <div class="font-light text-gray-400">{{ t('catalog.details.pricesTab.minPrice') }}</div>
                            <div>{{ data.detail.currency_symbol }} {{ data.prices?.min }}</div>
                        </div>

                        <div class="flex justify-between items-center mt-1 text-sm" v-if="data.prices?.max">
                            <div class="font-light text-gray-400">{{ t('catalog.details.pricesTab.maxPrice') }}</div>
                            <div>{{ data.detail.currency_symbol }} {{ data.prices?.max }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Market price -->
            <div v-if="data?.price_info" class="mb-4 flex flex-col bg-gray-800 sm:rounded-lg p-4">

                <h3 class="text-white text-2xl font-bold mb-4">{{ t('catalog.details.marketPrices') }}</h3>

                <div class="flex items-center text-xs uppercase text-gray-400">
                    <div class="w-[122px]"></div>
                    <div class="w-32">Min</div>
                    <div class="w-32">Max</div>
                    <div class="w-32">Avg</div>
                    <div class="w-32">30 days change</div>
                    <div class="w-32">60 days change</div>
                </div>

                <div class="divide-y divide-gray-600">

                    <div v-for="(countryPrice, index) in data.price_info.prices" class="flex items-center py-3">
                        <Flag :country="countryPrice.country" :show="userStore.isMultiCountry" :size="10" />

                        <div>
                            <div class="flex items-center" v-if="userStore.extra?.resell_enabled">
                                <div class="w-24 font-bold text-xs uppercase text-right pr-4 text-gray-600">Resell</div>

                                <div class="w-32">
                                    <p class="text-xs font-medium text-gray-500">
                                        <span class="text-xs text-gray-300" v-if="countryPrice.resell?.min_price">{{ countryPrice.currency_symbol }} {{ countryPrice.resell.min_price }}</span>
                                        <span class="text-xs text-gray-300" v-else>-</span>
                                    </p>
                                </div>

                                <div class="w-32 ">
                                    <p class="text-xs font-medium text-gray-500">
                                        <span class="text-xs text-gray-300" v-if="countryPrice.resell?.max_price">{{ countryPrice.currency_symbol }} {{ countryPrice.resell.max_price }}</span>
                                        <span class="text-xs text-gray-300" v-else>-</span>
                                    </p>
                                </div>

                                <div class="w-32">
                                    <p class="text-xs font-medium text-gray-500">
                                        <span class="text-xs text-gray-300" v-if="countryPrice.resell?.avg_price">{{ countryPrice.currency_symbol }} {{ countryPrice.resell.avg_price }}</span>
                                        <span class="text-xs text-gray-300" v-else>-</span>
                                    </p>
                                </div>

                                <div class="w-32">
                                    <p class="text-xs font-medium text-gray-500">
                                        <span v-if="countryPrice.resell?.last30_days_price_change" class="text-gray-300 text-xs font-medium inline-flex items-center">
                                            <svg v-if="countryPrice.resell.last30_days_price_change < 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-1">
                                              <path fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z" clip-rule="evenodd" />
                                            </svg>

                                            <svg v-if="countryPrice.resell.last30_days_price_change > 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-1">
                                              <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
                                            </svg>

                                            {{ countryPrice.resell.last30_days_price_change}}%
                                        </span>

                                        <span class="text-xs text-gray-300" v-else>-</span>
                                    </p>
                                </div>

                                <div class="w-32">
                                    <p class="text-xs font-medium text-gray-500">
                                        <span v-if="countryPrice.resell?.last60_days_price_change" class="text-gray-300 text-xs font-medium inline-flex items-center">
                                            <svg v-if="countryPrice.resell.last60_days_price_change < 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-1">
                                              <path fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z" clip-rule="evenodd" />
                                            </svg>

                                            <svg v-if="countryPrice.resell.last60_days_price_change > 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-1">
                                              <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
                                            </svg>

                                            {{ countryPrice.resell.last60_days_price_change}}%
                                        </span>

                                        <span class="text-xs text-gray-300" v-else>-</span>
                                    </p>
                                </div>
                            </div>

                            <div class="flex items-center" v-if="userStore.extra?.buyback_enabled">

                                <div class="w-24 text-xs font-bold uppercase text-right pr-4 text-gray-600">Trade In</div>

                                <div class="w-32">
                                    <p class="text-xs font-medium text-gray-500">
                                        <span class="text-xs text-gray-300" v-if="countryPrice.buyback?.min_price">{{ countryPrice.currency_symbol }} {{ countryPrice.buyback.min_price }}</span>
                                        <span class="text-xs text-gray-300" v-else>-</span>
                                    </p>
                                </div>

                                <div class="w-32 ">
                                    <p class="text-xs font-medium text-gray-500">
                                        <span class="text-xs text-gray-300" v-if="countryPrice.buyback?.max_price">{{ countryPrice.currency_symbol }} {{ countryPrice.buyback.max_price }}</span>
                                        <span class="text-xs text-gray-300" v-else>-</span>
                                    </p>
                                </div>

                                <div class="w-32">
                                    <p class="text-xs font-medium text-gray-500">
                                        <span class="text-xs text-gray-300"  v-if="countryPrice.buyback?.avg_price">{{ countryPrice.currency_symbol }} {{ countryPrice.buyback.avg_price }}</span>
                                        <span class="text-xs text-gray-300" v-else>-</span>
                                    </p>
                                </div>

                                <div class="w-32">
                                    <p class="text-xs font-medium text-gray-500">
                                        <span v-if="countryPrice.buyback?.last30_days_price_change" class="text-gray-300 text-xs font-medium inline-flex items-center">
                                            <svg v-if="countryPrice.buyback.last30_days_price_change < 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-1">
                                              <path fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z" clip-rule="evenodd" />
                                            </svg>

                                            <svg v-if="countryPrice.buyback.last30_days_price_change > 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-1">
                                              <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
                                            </svg>

                                            {{ countryPrice.buyback.last30_days_price_change}}%
                                        </span>

                                        <span class="text-xs text-gray-300" v-else>-</span>
                                    </p>
                                </div>

                                <div class="w-32">
                                    <p class="text-xs font-medium text-gray-500">

                                        <span v-if="countryPrice.buyback?.last60_days_price_change" class="text-gray-300 text-xs font-medium inline-flex items-center">
                                            <svg v-if="countryPrice.buyback.last60_days_price_change < 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-1">
                                              <path fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z" clip-rule="evenodd" />
                                            </svg>

                                            <svg v-if="countryPrice.buyback.last60_days_price_change > 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-1">
                                              <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
                                            </svg>

                                            {{ countryPrice.buyback.last60_days_price_change }}%
                                        </span>

                                        <span class="text-xs text-gray-300" v-else>-</span>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Competitor by grade -->
        <div class="w-1/5 bg-gray-800 sm:rounded-lg p-4 text-gray-400" v-if="data">
            <h4 class="font-medium text-white mb-2">{{ t('catalog.details.competitorByGrade') }}</h4>

            <div class="divide-y divide-gray-700">
                <div class="flex flex-row justify-between py-1" v-for="competitor in data.competitor">
                    <div><Grade :big="true" :grade="competitor.grade" /></div>
                    <div>{{ competitor.counter }}</div>
                </div>
            </div>
        </div>


    </div>


    <!-- Chart -->
    <div class="flex space-x-4">
        <div class="w-2/3 ">
            <div class="flex flex-col bg-gray-800 sm:rounded-lg p-4" v-show="type === 'resell'">
                <h3 class="text-white text-2xl font-bold mb-4">{{ t('catalog.details.pricesHistoricalResell') }}</h3>
                <canvas ref="resellChart"/>
            </div>

            <div class="flex flex-col bg-gray-800 sm:rounded-lg p-4" v-show="type === 'buyback'">
                <h3 class="text-white text-2xl font-bold mb-4">{{ t('catalog.details.pricesHistoricalBuyback') }}</h3>
                <canvas ref="buybackChart"/>
            </div>
        </div>

        <div class="w-1/5">
            <button type="button" class="text-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 bg-blue-600 hover:bg-blue-700 focus:outline-none" v-show="type === 'resell'" @click="showBuybackData">Show Trade In Data</button>
            <button type="button" class="text-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 bg-blue-600 hover:bg-blue-700 focus:outline-none" v-show="type === 'buyback'" @click="showResellData">Show Resell Data</button>
        </div>
    </div>

    <div class="rounded-lg mt-12" v-show="type === 'resell'">>
        <h2 class="text-xl font-bold dark:text-white mb-4">Resell Online items</h2>
        <div class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
            <div v-if="data?.data.resell?.items.length === 0" class="p-3 text-gray-400">
                There are no resell items online
            </div>

            <div v-else class="overflow-x-auto">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.details.table.headings.color') }}</th>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.details.table.headings.battery') }}</th>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.details.table.headings.price') }}</th>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.details.table.headings.country') }}</th>
                        <th scope="col" class="px-2 py-3">Competitor</th>
                        <th scope="col" class="px-2 py-3">Review</th>
                        <th scope="col" class="px-2 py-3">Link</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr v-for="(item, index) in data?.data.resell?.items"
                        class="dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 border-b">
                        <td class="px-2 py-3 capitalize">
                            <span>{{ item.color ?? '-' }}</span>
                        </td>

                        <td class="px-2 py-3 capitalize">
                            <span>{{ item.battery ?? 'Standard' }}</span>
                        </td>

                        <td class="px-2 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            <div class="flex items-center">{{ item.currency_symbol }} {{ item.price }}</div>
                        </td>

                        <td class="px-2 py-3 text-xs capitalize">
                            <Flag :country="item.vendor.country" :show="true" :size="10" />
                        </td>

                        <td class="px-2 py-3 text-xs">
                            <div class="text-xs text-gray-300">{{ item.seller_name }}</div>
                            <div class="text-xs font-medium text-gray-500">{{ item.vendor.name }}</div>
                        </td>

                        <td class="px-2 py-3">
                            <div class="text-xs font-medium flex items-center" v-if="item.vendor.reviews_url !== null">
                                <svg aria-hidden="true" class="w-3 h-3 text-yellow-400" fill="currentColor"
                                     viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                </svg>

                                <p class="ml-2 text-sm"><a class="underline hover:text-white transition-colors" :href="item.vendor.reviews_url" target="_blank">{{ item.vendor.rating }} su 5</a></p>
                            </div>
                        </td>

                        <td class="px-2 py-3" v-if="item.url">
                            <a :href="item.url" target="_blank" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">{{ t('catalog.details.table.open') }}</a>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="rounded-lg mt-12" v-show="type === 'buyback'">>
        <h2 class="text-xl font-bold dark:text-white mb-4">Trade In Online items</h2>
        <div class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
            <div v-if="data?.data.buyback?.items.length === 0" class="p-3 text-gray-400">
                There are no trade-in items online
            </div>

            <div class="overflow-x-auto" v-else>
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.details.table.headings.color') }}</th>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.details.table.headings.battery') }}</th>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.details.table.headings.price') }}</th>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.details.table.headings.country') }}</th>
                        <th scope="col" class="px-2 py-3">Competitor</th>
                        <th scope="col" class="px-2 py-3">Review</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr v-for="(item, index) in data?.data.buyback?.items"
                        class="dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 border-b">
                        <td class="px-2 py-3 capitalize">
                            <span>{{ item.color ?? '-' }}</span>
                        </td>

                        <td class="px-2 py-3 capitalize">
                            <span>{{ item.battery ?? 'Standard' }}</span>
                        </td>

                        <td class="px-2 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            <div class="flex items-center">{{ item.currency_symbol }} {{ item.price }}</div>
                        </td>

                        <td class="px-2 py-3 text-xs capitalize">
                            <Flag :country="item.vendor.country" :show="true" :size="10" />
                        </td>

                        <td class="px-2 py-3 text-xs">
                            <div class="text-xs text-gray-300">{{ item.seller_name }}</div>
                            <div class="text-xs font-medium text-gray-500">{{ item.vendor.name }}</div>
                        </td>

                        <td class="px-2 py-3">
                            <div class="text-xs font-medium flex items-center" v-if="item.vendor.reviews_url !== null">
                                <svg aria-hidden="true" class="w-3 h-3 text-yellow-400" fill="currentColor"
                                     viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                </svg>

                                <p class="ml-2 text-sm"><a class="underline hover:text-white transition-colors" :href="item.vendor.reviews_url" target="_blank">{{ item.vendor.rating }} su 5</a></p>
                            </div>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>


    <div class="rounded-lg mt-12" v-if="data?.logs.length">
        <h2 class="text-xl font-bold dark:text-white mb-4">Price update logs</h2>
        <div class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
            <div class="overflow-x-auto">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="px-2 py-3">Date</th>
                        <th scope="col" class="px-2 py-3">Event</th>
                        <th scope="col" class="px-2 py-3">Details</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr v-for="(log, index) in data.logs"
                        class="dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 border-b">
                        <td class="px-2 py-3 text-xs">
                            <span>{{ log.created_at }}</span>
                        </td>

                        <td class="px-2 py-3 text-xs">
                            <span>{{ log.event }}</span>
                        </td>

                        <td class="px-2 py-3 text-xs">
                            <span>{{ log.note }}</span>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <Alert :message="alertMessage" />
    <MoreInfo :show="showMoreInfoPanel" />
</template>
