import {DirectiveBinding} from "vue";

let handler: any = null;

const onClickOutside = (event: MouseEvent, el: HTMLElement, handler: any) => {

    const isClickOutside = event.target !== el && !el.contains(event.target as HTMLElement);

    if (typeof handler !== 'function') {
        throw new Error('click-outside handler must be a function');
    }

    if (isClickOutside) {
        return handler(event, el);
    }

    return null;
}



const clickOutside = {
    mounted: (el: HTMLElement, binding: DirectiveBinding) => {
        handler = (event: MouseEvent) => onClickOutside(event, el, binding.value);
        document.addEventListener('click', handler, true);
    },
    unmounted: (el: HTMLElement, binding: DirectiveBinding) => {
        document.removeEventListener('click', handler, true);
    }
}

export default clickOutside;
