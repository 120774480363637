<script setup lang="ts">

import {ref} from "vue";
import {Strategy} from "../types";
import axios from "axios";
import {useI18n} from "vue-i18n";
import {useUserStore} from "../stores/UserStore";
const {t} = useI18n();
const user = useUserStore();

const props = defineProps<{
    strategy: Strategy;
}>();

const emit = defineEmits(['success', 'error', 'submit'])

const strategy = ref(props.strategy);

let absValue: number = Math.abs(strategy.value.values.value);
let higherOrLower: string = strategy.value.values.value > 0 ? 'higher' : 'lower';

const onRoundingTypeChange = (event: Event) => {
    switch (strategy.value.values.rounding_type) {
        case 'point_nine':
            strategy.value.values.rounding_value = .9;
            break;
        case 'nine_euro':
            strategy.value.values.rounding_value = 9;
            break;
        default:
            break;
    }
}

const onSubmitVariation = async (event: Event) => {
    emit('submit');

    try {
        const response = await axios.put('/api/strategies/' + strategy.value.category.id, {
            type: strategy.value.type,
            values: {
                rounding_value: Number(strategy.value.values.rounding_value),
                rounding_type: strategy.value.values.rounding_type,
                min_price_percentage: Number(strategy.value.values.min_price_percentage),
                max_price_percentage: Number(strategy.value.values.max_price_percentage),
            },
        });

        emit('success', response.data);

    } catch (error: any) {
        emit('error', error.response.data.message);
    }
}


const onSubmitMarket = async (event: Event) => {
    emit('submit');

    try {
        const response = await axios.put('/api/strategies/' + strategy.value.category.id, {
            type: strategy.value.type,
            values: {
                value: higherOrLower === 'higher' ? absValue : -absValue,
                comparison: strategy.value.values.comparison,
                market_price: strategy.value.values.market_price,
                margin: Number(strategy.value.values.margin),
            },
        });

        emit('success', response.data);

    } catch (error: any) {
        emit('error', error.response.data.message);
    }
}

</script>

<template>
    <div class="w-full bg-gray-800 sm:rounded-lg p-4 text-gray-400 mt-8">
        <h4 class="text-lg font-medium text-white mb-2">{{ t('strategies.card.title', {category: strategy.category.name}) }}</h4>

        <form v-if="strategy.type === 'variation'" @submit.prevent="onSubmitVariation">
            <div class="leading-10">
                {{ t('strategies.card.minSentence') }}

                <div class="inline-flex w-24 mx-4 relative">
                    <span class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-gray-400">%</span>
                    <span class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-gray-400">-</span>
                    <input v-model="strategy.values.min_price_percentage" type="text" id="min-price" class="px-8 py-1.5 focus:outline-none rounded-lg bg-gray-700 border border-gray-600 text-white focus:border-blue-500 block w-full text-sm">
                </div>

                {{ t('strategies.card.maxSentence') }}

                <div class="inline-flex w-24 mx-4 relative">
                    <span class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-gray-400">%</span>
                    <span class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-gray-400">+</span>
                    <input v-model="strategy.values.max_price_percentage" type="text" id="min-price" class="px-8 py-1.5 focus:outline-none rounded-lg bg-gray-700 border border-gray-600 text-white focus:border-blue-500 block w-full text-sm">
                </div>

                {{ t('strategies.card.roundSentence') }}

                <div class="inline-flex w-36 mx-4 relative">
                    <select @change="onRoundingTypeChange" v-model="strategy.values.rounding_type" class="py-1.5 px-2.5 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full bg-gray-700 dark:border-gray-600 placeholder-gray-400 text-white dark:focus:ring-blue-500">
                        <option value="fixed">{{ user.extra?.catalog_currency }}</option>
                        <option value="nine">9 {{ user.extra?.catalog_currency }}</option>
                        <option value="point_nine">.9 {{ user.extra?.catalog_currency }}</option>
                    </select>
                </div>

                <div class="inline-flex" v-show="strategy.values.rounding_type === 'percentage' || strategy.values.rounding_type === 'fixed'">
                    {{ t('strategies.card.of') }}

                    <div class="inline-flex w-20 mx-4 relative">
                        <span class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-gray-400" v-if="strategy.values.rounding_type === 'percentage'">%</span>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-gray-400" v-if="strategy.values.rounding_type !== 'percentage'">{{ user.extra?.catalog_currency_symbol }}</span>
                        <input v-model="strategy.values.rounding_value" type="text" id="min-price" class="pl-2.5 pr-8 py-1.5 focus:outline-none rounded-lg bg-gray-700 border border-gray-600 text-white focus:border-blue-500 block w-full text-sm">
                    </div>
                </div>

            </div>

            <div class="flex mt-4">
                <button type="submit" class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800">{{ t('strategies.card.apply') }}</button>
            </div>
        </form>

        <form v-if="strategy.type === 'market'" @submit.prevent="onSubmitMarket">
            I want the suggested price to be

            <div class="inline-flex w-12 mx-4 relative">
                <input v-model="absValue" type="text" class="px-2.5 py-1.5 focus:outline-none rounded-lg bg-gray-700 border border-gray-600 text-white focus:border-blue-500 block w-full text-sm">
            </div>

            <div class="inline-flex w-24 mx-4 relative">
                <select v-model="strategy.values.comparison" class="py-1.5 px-2.5 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full bg-gray-700 dark:border-gray-600 placeholder-gray-400 text-white dark:focus:ring-blue-500">
                    <option value="percentage">%</option>
                    <option value="fixed">{{ user.extra?.catalog_currency_symbol }}</option>
                </select>
            </div>

            <div class="inline-flex w-32 mx-4 relative">
                <select v-model="higherOrLower" class="py-1.5 px-2.5 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full bg-gray-700 dark:border-gray-600 placeholder-gray-400 text-white dark:focus:ring-blue-500">
                    <option value="higher">higher</option>
                    <option value="lower">lower</option>
                </select>
            </div>

            than the

            <div class="inline-flex w-48 mx-4 relative">
                <select v-model="strategy.values.market_price" class="py-1.5 px-2.5 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full bg-gray-700 dark:border-gray-600 placeholder-gray-400 text-white dark:focus:ring-blue-500">
                    <option value="median">median market price</option>
                    <option value="max">max market price</option>
                    <option value="min">min market price</option>
                </select>
            </div>

            but higher than

            <div class="inline-flex w-20 mx-4 relative">
                <span class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-gray-400">%</span>
                <input v-model="strategy.values.margin" type="text" id="min-price" class="pl-2.5 pr-8 py-1.5 focus:outline-none rounded-lg bg-gray-700 border border-gray-600 text-white focus:border-blue-500 block w-full text-sm">
            </div>

            margin

            <div class="flex mt-4">
                <button type="submit" class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800">{{ t('strategies.card.apply') }}</button>
            </div>
        </form>
    </div>
</template>
