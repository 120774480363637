<script setup lang="ts">
import { ref, defineProps } from 'vue';

type Filter = {
    label: string,
    value: string,
}
const props = defineProps<{
    value: string[],
    options: Filter[],
    show: boolean,
    buttonLabel?: string,
    optionsLabel?: string,
}>();

const emit = defineEmits(['update:value']);
const opened = ref(false);

const onChange = (optionValue: string, event: Event) => {
    const updatedValue = [...props.value];
    const target = event.target as HTMLInputElement;

    if (!target) return;

    if (target.checked) {
        updatedValue.push(optionValue);
    } else {
        updatedValue.splice(updatedValue.indexOf(optionValue), 1);
    }

    emit('update:value', updatedValue);
}
</script>


<template>
    <div v-if="props.show">
        <div class="relative" v-click-outside="() => opened = false" >

            <button
                @click.prevent="opened = true"
                class="w-48 rounded-lg border text-center inline-flex items-center justify-between me-2 px-3 py-2 text-xs font-medium outline-none bg-gray-800 text-gray-400 border-gray-700 hover:text-white hover:bg-gray-700">
                <span>{{ props.buttonLabel }}</span>
<!--                <svg class="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>-->
<!--                </svg>-->
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 ml-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                </svg>

<!--                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4 ml-2">-->
<!--                    <path fill-rule="evenodd" d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />-->
<!--                </svg>-->

            </button>

            <transition
                enter-from-class="-translate-y-6 opacity-0 scale-75"
                enter-to-class="translate-y-0 opacity-100 scale-100"
                leave-from-class="translate-y-0 opacity-100 scale-100"
                leave-to-class="-translate-y-6 opacity-0 scale-75" >
                <div v-if="opened" class="absolute z-20 top-0 w-64 p-3 rounded-lg bg-gray-800 border border-gray-700 shadow-xl" style="left: -265px">
                    <h6 class="mb-3 text-xs font-medium text-white">{{ props.optionsLabel }}</h6>

                    <ul class="space-y-2 text-sm">
                        <li class="flex items-center relative" v-for="option in options">
                            <input
                                type="checkbox"
                                :id="option.value"
                                :value="option.value"
                                :checked="props.value.includes(option.value)"
                                @change="onChange(option.value, $event)"
                                class="cursor-pointer peer appearance-none w-4 h-4 mr-1 rounded bg-gray-700 border border-gray-600 checked:bg-blue-600 checked:border-blue-600"
                            />

                            <label :for="option.value" class="cursor-pointer flex items-center ml-2 text-xs font-medium text-gray-400 truncate ...">
                                {{ option.label }}
                            </label>

                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="cursor-pointer pointer-events-none size-3 hidden peer-checked:block absolute fill-slate-200" style="left: 1px">
                                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z" clip-rule="evenodd" />
                            </svg>
                        </li>
                    </ul>
                </div>
            </transition>
        </div>
    </div>
</template>
