<script setup lang="ts">

import {Ref, ref} from "vue";
import {AlertMessage, Strategy} from "../types";
import {useUserStore} from "../stores/UserStore";
import Alert from "./Alert.vue";
import StrategyCard from "./StrategyCard.vue";
import {useI18n} from "vue-i18n";

const {t} = useI18n();
const user = useUserStore();
const strategies: Ref<Array<Strategy> | null> = ref(user.strategies);
const alertMessage: Ref<AlertMessage | null> = ref(null);

const onSubmit = (event: Event) => {
    alertMessage.value = null;
}

const onSuccess = (strategies: Array<Strategy>) => {
    alertMessage.value = {
        type: 'success',
        message: t('strategies.messages.strategyUpdated'),
    }

    user.updateStrategies(strategies);
}

const onError = (message: string) => {
    alertMessage.value = {
        type: 'error',
        message: message,
    }
}

</script>

<template>
    <h2 class="text-4xl font-bold dark:text-white">{{ t('strategies.resell.title') }}</h2>
    <p class="my-4 text-sm text-gray-500" v-html='t("strategies.resell.subTitle")' />

    <StrategyCard v-for="strategy in user.strategies"
                  :key="strategy.id"
                  :strategy="strategy"
                  @success="onSuccess"
                  @error="onError"
                  @submit="onSubmit"
    />

    <Alert :message="alertMessage" />
</template>
